import * as React from "react"

// styles
//const center = {marginLeft: "auto", marginRight: "auto"}
const center = {textAlign: "center"}

// markup
const NotFoundPage = () => {
  return (
    <main>
      <title>Sidan finns inte</title>
      <div style={center}>
        <p>Sidan finns inte</p>
        <div>
          <a href="/">Huvidsida</a>
        </div>
      </div>
    </main>
  )
}

export default NotFoundPage
